var site = site || {};
site.product = site.product || {};
site.product.view = site.product.view || {};


site.product.view.brief = function (args) {

  var that = {};

  that.$productView = $(args.containerNode);
  if (that.$productView.length < 1) return null;

  that.productData = args.productData;
  if (typeof that.productData != "object") return null;

  that.productId = args.productData.PRODUCT_ID;
  that.catId = that.productData.PARENT_CAT_ID;
  that.tagIds = that.catId +  '~' + that.productId;

  var isShaded = that.productData.shaded;
  var $productImageContainer = $('.product_brief__image-container', that.$productView);
  var $productImage = $('.product_brief__image', that.$productView);
  var $productHeaders = $('.product_brief__headers', that.$productView);
  var $productPanel = $('.product_brief__panel', that.$productView);
  var $productSubPanels = $('.product_brief__sub-panel', that.$productView);
  var $productDescSubPanel = $('.product_brief__description .product_brief__sub-panel', that.$productView);
  var $productButtonSub = $('.product_brief__buttons-container .product_brief__sub-panel', that.$productView);
  var $closePanel = $('.product_brief__sub-panel-close', that.$productView);
  var $productSelects = $('.product_brief__quantity, .product_brief__sku-sizes__select', that.$productView);
  var $quantitySelect = $('.product_brief__quantity', that.$productView);
  var $skintypeSelect = $('.product-brief__skintype-select', that.$productView);
  var $addToBag = $('.product_brief__button--add-to-bag', that.$productView);

  //$productSelects.selectBox();
  $productImage.css('background-image','url(' + that.productData.skus[0].MEDIUM_IMAGE + ')');
  $addToBag.unbind('click');
  var $btnProductPanel = $('.product_brief__button--shop-now', that.$productView);
  $btnProductPanel.click( function(e) {
    e.preventDefault();
    $(document).trigger("MPP:productQV", that.tagIds);
    openSubPanel(that.productId);
  });

  if(isShaded){
    var shadePicker = new site.ShadePicker(that.productData);
    var $swatchSelect = $('select.product_brief__swatch-select', that.$productView);
    var $shadeList = $('.shade-list', that.$productView);
    var $shadeListSwatches = $('.shade-list .swatch', that.$productView); // <a>
    var $miscFlag = $('.product_brief__misc-flag-sku', that.$productView);
    var $miscFlagREF = $('.product_brief__misc-flag-reference', that.$productView);

    function miscFlagValue(val){
      var value = val;
      if(value>=1 || value>=2 || value>=3 || value>=5 || value>=94){
        if(value == 5 || value == 94){
          value = 4; // 94 and 5 the same
        }
        var miscFlagText = $miscFlagREF.filter("[data-misc-flag=" + value + "]").html();
        $miscFlag.html(miscFlagText);
      }else{
        $miscFlag.html('');
      }
    }

    if($swatchSelect.length > 0){
      // Top Selling
      that.bestSellingSkus = that.productData.BEST_SELL_SKU_BASE_ID;
      function bestSellersSort(){
        var $swatchLis = $shadeListSwatches.parent();
        // hide all swatch LI's
        $swatchLis.hide();
        // set flag for selecting first SKU
        var select = true;
        // iterate over swatch LI's
        $swatchLis.each(function(i, ele) {
          var $swatchLi = $(this);
          var skuBaseId = $swatchLi.attr('data-sku-base-id');
          // if this swatch is in the best-seller list
          if (_.contains(that.bestSellingSkus, skuBaseId)) {
            $swatchLi.show();
            // select the first best-selling SKU
            if (select) {
              var skuDataToSelect = _.find(that.productData.skus, function(s){ return s.SKU_BASE_ID == skuBaseId; });
              site.skuSelect(skuDataToSelect);
              // set shade name
              // TODO attach this to the sku:select event instead
              $('.product_brief__shadename', that.$productView).html(skuDataToSelect.SHADENAME);
              miscFlagValue(skuDataToSelect.MISC_FLAG);
              select = false;
            }
          }
        });
      }
      if(!_.isUndefined(that.bestSellingSkus) && !_.isNull(that.bestSellingSkus)){
        $('.product_brief__all-shades', that.$productView).remove();
        that.bestSellingSkus = that.bestSellingSkus.split(',');
        //console.log(that.bestSellingSkus);
        bestSellersSort();
      }else{
        $('.product_brief__top-shades', that.$productView).remove();
      }

      // colorSelect + intensity
      $swatchSelect.selectBox({mobile: true});
      function updateSwatchSelect(select){
        $(select).selectBox('refresh');
        var $select = $(select).selectBox('control');
        var $selectOption =  $($select).data('selectBox-options');
        var $selectMenuOptions = $('li a', $selectOption);
        // add shade divs to custom select
        $selectMenuOptions.each(function() {
          var swatchClass = $(this).attr('rel');
          swatchClass = 'filter-' + swatchClass.toLowerCase().replace(/ /g,"_");
          swatchClass = swatchClass.toLowerCase();
          $(this).prepend( '<div class="filtered-swatch ' + swatchClass + '"></div>');
        });
      }
      updateSwatchSelect($swatchSelect);

      $swatchSelect.change(function(event) {
        updateSwatchSelect($(this));
        // var that.productData = _.find(page_data['catalog-mpp'].categories[0].products, function(p){ return p.PRODUCT_ID == that.productId; });
        if(!$(this)[0].selectedIndex){
          //console.log( 'top selling');
          if( $('.product_brief__top-shades', $(this)).length ){
            bestSellersSort();
          }else{
            $shadeListSwatches.parent().show();
            $shadeListSwatches.eq(0).trigger('click');
          }
        }else{
          var swatchFilter = $(this).val();
          // figure out if MISC_FLAG, ATTRIBUTE_COLOR_FAMILY or INTENSITY
          var isMiscFlag = $(this).find(":selected").hasClass('product_brief__misc-flag-option');
          if( isMiscFlag ){
            var miscFlagData = $(this).find(":selected").attr('data-misc-flag');
            var swatchFilterSkus = _.filter(that.productData.skus, function(s){ return s.MISC_FLAG == miscFlagData; });
          }else if( $(this).hasClass('color-family') ){
            var swatchFilterSkus = _.filter(that.productData.skus, function(s){ return s.ATTRIBUTE_COLOR_FAMILY == swatchFilter; });
          }else if ( $(this).hasClass('intensity') ){
            var swatchFilterSkus = _.filter(that.productData.skus, function(s){ return s.INTENSITY == swatchFilter; });
          }

          $shadeListSwatches.parent().hide();
          $(swatchFilterSkus).each(function( index, sku) {
            var skuID = sku.SKU_BASE_ID;
            var $swatch = $shadeListSwatches.filter("[data-sku-base-id=" + skuID +  "]").parent();
            $swatch.show();
          });
          //select first swatch in list
          $shadeListSwatches.filter("[data-sku-base-id=" + swatchFilterSkus[0].SKU_BASE_ID + "]").trigger('click');
          site.skuSelect(swatchFilterSkus[0]);
        }
      });
    }

    $shadeListSwatches.click(function(event) {
      var shadename = $(this).attr('name');
      $('.product_brief__shadename', that.$productView).html(shadename);
      var miscFlagSKU = $(this).attr('data-sku-base-id');
      var miscFlagVal = _.filter(that.productData.skus, function(s){ return s.SKU_BASE_ID == miscFlagSKU; });
      miscFlagValue(miscFlagVal[0].MISC_FLAG);
    });
  } // end shaded

  //non shaded - multiple sizes
  var $sizeSelect = $('.js-size-select', that.$productView);
  if($sizeSelect.length && $skintypeSelect.length){
    function updateSizeSelect(){
      $('option', $sizeSelect).prop('disabled', true);
      var selectedSkus = $skintypeSelect.find('option:selected').attr('data-skus').split(',');
      $(selectedSkus).each(function(index, val) {
        var $option = $('option', $sizeSelect).filter("[data-sku-base-id=" + val +  "]");
        $option.prop('disabled', false);
        if(index == 0){
          $option.prop('selected', true);
        }
      });
      $sizeSelect.trigger('change');
    }
    updateSizeSelect();
  }

  if($sizeSelect.length){
    $sizeSelect.selectBox();
  }

  $sizeSelect.change(function(event) {
    var selectedSku = $(this).find('option:selected').attr('data-sku-base-id');
    var skuData = _.find(that.productData.skus, function(sku){ return sku.SKU_BASE_ID== selectedSku; });
    site.skuSelect(skuData);
  });

  $quantitySelect.change(function(event) {
    var quantity = $(this).val();
    site.qtySelect(that.productId, quantity);
  });

    //get skus to show from skintype
  //if size exists
  if($sizeSelect.length){

  }

  // only use skintype as reference, set sku from size select
  $skintypeSelect.change(function(event) {
    if($sizeSelect.length){
      updateSizeSelect();
      $sizeSelect.selectBox('refresh');
    }else{
      var selectedSku = $(this).find('option:selected').attr('data-skus');
      //console.log(selectedSku);
      var skuData = _.find(that.productData.skus, function(sku){ return sku.SKU_BASE_ID== selectedSku; });
      site.skuSelect(skuData);
    }
  });

  // var $invStatusList = $(".js-inv-status-list", that.$productView);
  // var invStatusList = site.productView.InvStatusList($invStatusList);

  $productImage.on('sku:select', function(e, skuData) {
    $productImage.css('background-image','url(' + skuData.MEDIUM_IMAGE[0] + ')');
    e.stopPropagation();
  });

  var openSubPanel = function() {
    if(!$productImageContainer.hasClass('is-open')){
      // check sub-panel against prod thumb height
      var productOffsetHeight = $productHeaders.outerHeight() + $productDescSubPanel.outerHeight() + $productButtonSub.outerHeight() + 172 + 20; //small image frame = 206, extra button pad = 20
      var productCurrentHeight = that.$productView.closest('.mpp__product').outerHeight();

      var showPanel = function() {
        //$productImage.addClass('is-open');
        $productImageContainer.addClass('is-open');
        $productPanel.fadeOut( 400, function() {
          $productSubPanels.fadeIn( 400, function() {
		   $('.descscrollbar').tinyscrollbar();
            $closePanel.removeClass('hidden');
          });
        });
        if (typeof site.product !== 'undefined' && typeof site.product.view !== 'undefined' && typeof site.product.view.equalRowHeight !== 'undefined') {
          site.product.view.equalRowHeight($('.mpp__product'));
        }
      };

      if(productOffsetHeight > productCurrentHeight) {
        //expand bottom padding to fit $productButtonSub + additional 20px;
        that.$productView.closest('.mpp__product').animate({ height: productOffsetHeight }, 100, showPanel);
      }else if(productOffsetHeight <= productCurrentHeight){
        //if panel fits, just open
        showPanel();
      }
    }
    $closePanel.unbind('click');
    // TODO: collapse entire row if all are closed
    $closePanel.click( function(e) {
      e.preventDefault();
      $productImageContainer.removeClass('is-open');
      $productSubPanels.hide();
      $productPanel.fadeIn(400);
      $(this).addClass('hidden');
    });
  }; // end openSubPanel

  return that;
};
